// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    production: false,
    isAdminSite: false,
    isMultiTenant: true,
    disableSSO: false,
    name: 'feature',
    storageHubUrl: 'http://here_should_be_app_url_for_server_env/hub.html',
    apiUrl: 'http://here_should_be_api_url_for_server_env',
    googleAuthClientId: '',
    featureFlagEnvironmentID: '',
    featureFlagEnableCache: false
};
